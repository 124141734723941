<template>
	<div class="uu-decorate">
		<div class="uu-decorate-head">
			<router-link to="/" class="head-logo"><img src="@/assets/images/seller/decorate-logo-white.png" ></router-link>
			<div class="head-nav">
				<el-menu :default-active="activeMenu" mode="horizontal" router>
					<el-menu-item index="decorateCategory" route="/home/decorate/category">{{$t('seller.header.goodsCate')}}</el-menu-item>
					<el-menu-item index="decorateMaterial" route="/home/decorate/material">{{$t('seller.header.material')}}</el-menu-item>
				</el-menu>
			</div>
			<div class="head-account">
				<el-dropdown>
					<span class="el-dropdown-link">
						{{userInfo.storeName}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu class="uu-decorate-head-dropdown" slot="dropdown">
						<el-dropdown-item @click.native="handleLogout">{{$t('seller.header.logout')}}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="uu-decorate-body">
			<router-view>
			</router-view>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			]),
			activeMenu() {
				const route = this.$route
				const { name } = route
				return name
			}
		},
		data() {
			return {
			}
		},
		methods: {
			async handleLogout(){
				await this.$store.dispatch('user/logout')
				window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-decorate {
		height: 100%;
		min-width: 1280px;
		.uu-decorate-head {
			height: 60px;
			background-color: #2A2A2A;
			.head-logo {
				float: left;
				color: #FFFFFF;
				font-size: 28px;
				height: 36px;
				line-height: 36px;
				padding: 12px 0;
				margin: 0 40px;
				img {
					height: 100%;
				}
			}
			.head-nav {
				float: left;
				::v-deep .el-menu {
					border: none;
					background-color: transparent;
					.el-menu-item {
						color: #999999;
						border-bottom: none;
					}
					.el-menu-item.is-active {
						color: #FFFFFF;
						background-color: #080808;
					}
					.el-menu-item:focus,.el-menu-item:hover {
						color: #FFFFFF;
						background-color: #080808;
					}
				}
			}
			.head-account {
				float: right;
				margin-right: 15px;
				::v-deep .el-dropdown {
					.el-dropdown-link {
						height: 60px;
						line-height: 60px;
						display: block;
						cursor: pointer;
						color: #A9A9A9;
					}
				}
			}
		}
	}
	.uu-decorate-body {
		height: calc(100% - 60px);
		::v-deep .el-scrollbar {
			height: 100%;
			.el-scrollbar__wrap {
				overflow-x: auto;
			}
		}
	}
	.el-dropdown-menu {
		background-color: #1f1f1f;
		border-color: #1f1f1f;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .48), 0 6px 16px 0 rgba(0, 0, 0, .32), 0 9px 28px 8px rgba(0, 0, 0, .2);
	
		.el-dropdown-menu__item {
			color: #a9a9a9;
		}
	
		.el-dropdown-menu__item:focus,
		.el-dropdown-menu__item:not(.is-disabled):hover {
			color: #a9a9a9;
			background-color: rgba(255, 255, 255, .1);
		}
	
		::v-deep .popper__arrow {
			border-bottom-color: #1f1f1f;
		}
	
		::v-deep .popper__arrow::after {
			border-bottom-color: #1f1f1f;
		}
	}
</style>
